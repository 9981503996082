import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Header from '../components/Header/header';
import Footer from '../components/Footer/footer';
import SEO from '../components/SEO/SEO';
import ProjectCard from '../components/Card/ProjectCard'
import { ping } from '../utils/utils.js';

import '../../static/common.scss';

import 'typeface-roboto';

const AboutMe = ({ data }) => {
  let siteMetadata = data.site.siteMetadata;

  // 为该页面重写部分seo配置
  siteMetadata.description = '关于zhuyuntao的个人信息';
  siteMetadata.keywords = 'zhuyuntao,个人博客站点';

  useEffect(() => {
    ping();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{ siteMetadata.siteTitle } - 关于我</title>
      </Helmet>
      <SEO site={ siteMetadata } />
      <Header { ...siteMetadata } />
      <div className="content-container">
        <div className="article-content">
          <Paper className="blog-post">
            <h1>关于我</h1>
            <div className="">
              <div>
                <h2>个人简介</h2>
                <ul className="about-me-info">
                  <li>姓名：ZYT</li>
                  <li>性别：未知</li>
                  <li>职业：码农</li>
                  <li>坐标：杭州</li>
                  <li>理想：全干攻城狮</li>
                  <li>Stay hungry, Stay foolish</li>
                  <li>echoweb@126.com</li>
                </ul>
              </div>
              <br />
              <div>
                <h2>开源项目</h2>
                <br />
                <Grid container spacing={ 0 }>
                  <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
                    <ProjectCard
                      name="js-audio-recorder"
                      imageUrl="https://image.zhuyuntao.cn/image/spc/recorder.png"
                      path="https://github.com/2fps/recorder"
                      desc="主要用于Web端录制音频。支持多通道，多采样率，多采样位的转化。"
                      npm="https://img.shields.io/npm/v/js-audio-recorder.svg"
                      star="https://img.shields.io/github/stars/2fps/recorder?style=social"
                      fork="https://img.shields.io/github/forks/2fps/recorder?style=social"
                    />
                  </Grid>
                </Grid>
                <br />
                {/* <Grid container spacing={ 0 }>
                  <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
                    <ProjectCard
                      name="gen (开发中)"
                      imageUrl="https://image.zhuyuntao.cn/image/spc/gen.png"
                      path="https://github.com/2fps/gen"
                      desc="静态博客辅助系统，主要负责静态系统中动态记录的功能，如访问量统计，留言等。gen(根)，作为树最重要的部分，支撑树的蓬勃生长。"
                      npm=""
                      star="https://img.shields.io/github/stars/2fps/gen?style=social"
                      fork="https://img.shields.io/github/forks/2fps/gen?style=social"
                    />
                  </Grid>
                </Grid> */}
              </div>
            </div>
          </Paper>
        </div>
      </div>
      <Footer { ...siteMetadata } />
    </div>
  );
}

export default AboutMe;

export const query = graphql`
query {
  site {
    siteMetadata {
      description
      siteTitle
      keywords
      imageUrl
      siteUrl
      author
      github
      menus {
        name
        path
      }
      bottomMenus {
        name
        path
      }
    }
  }
}
`;
