import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    maxWidth: 800,
    margin: '0 auto',
  },
  media: {
    height: 290,
  },
  desc: {
    height: 50,
  },
  subTitle: {
    float: 'right'
  }
});

const SubjectCard = ({
  name,
  path,
  imageUrl,
  desc,
  npm,
  star,
  fork,
}) => {
  const classes = useStyles();

  return (
    <Card className={ classes.card }>
      <CardMedia
        className={ classes.media }
        image={ imageUrl }
        title={ name }
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          { name }
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" className={ classes.desc }>
          { desc }
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <img src={ star } className="margin-right-1" />&nbsp;
          <img src={ fork } className="margin-right-1" />&nbsp;
          {
            npm && <img src={ npm } />
          }
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" component="a" target="_target" href={ path }>
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

export default SubjectCard;
